<template>
  <section
    class="preview-template-view"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <el-button
      v-if="!isHideFinishButton"
      class="btn button-center"
      @click="saveAndnext"
      style="margin-right: 0; background-color: #1a92e8; color: antiquewhite"
    >
      Finish
      <i class="el-icon-check"></i>
    </el-button>

    <!-- <el-button
        v-if="this.isExecute && hasNext"
        class="sub-btn button-center float-right"
        @click="saveAndnext"
      >
        Save &amp; Next
        <i class="el-icon-right"></i>
    </el-button>-->

    <!-- <div class="required-fields-info">
        <el-badge :value="getRquiredFieldsCount()" class="item" type="primary">
          <div>Filled</div>
        </el-badge>
        <el-badge :value="getTotalFieldsCount()" class="item" type="warning">
          <div>Required Fields</div>
        </el-badge>
    </div>-->

    <div class="template-body-section">
      <el-form>
        <div class="form-builder" ref="formBuilder" :style="getStylediv">
          <div v-if="templateInteractiveQuestions">
            <preview-interactive-template-form
              :fieldsData="fieldsData"
              :isExecute="isExecute"
              :form="form"
              :interactiveQuestionsModeType="interactiveQuestionsModeType"
              :dataId="entityDataId"
              :isFormFormbuilder="fromFormbuilder"
              @onFilledQuestion="updateInteractiveQuestionFillingStatus"
            ></preview-interactive-template-form>
          </div>
          <div
            v-else-if="
              fieldsData &&
              fieldsData.length &&
              !refreshTemplate &&
              !templateInteractiveQuestions
            "
            class="form-fields-holder"
          >
            <div
              v-for="(field, index) of getFieldsData"
              :key="index"
              :style="getStyle(field)"
            >
              <HorizontalLineExecute
                :data="field"
                v-if="field.input_type === 'HORIZONTAL_LINE'"
                :form="form"
                :is-view="!isExecute"
              ></HorizontalLineExecute>
              <DateExecute
                :data="field"
                v-if="field.input_type === 'DATE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></DateExecute>
              <DateTimeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></DateTimeExecute>
              <DateTimeRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME_RANGE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></DateTimeRangeExecute>
              <DateRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_RANGE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></DateRangeExecute>
              <TimeExecute
                :data="field"
                v-if="field.input_type === 'TIME'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></TimeExecute>
              <TimeRangeExecute
                :data="field"
                v-if="field.input_type === 'TIME_RANGE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></TimeRangeExecute>
              <FileExecute
                :data="field"
                v-if="field.input_type === 'FILE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></FileExecute>
              <MultiLineTextExecute
                :data="field"
                v-if="
                  field.input_type === 'MULTI_LINE_TEXT' ||
                  field.input_type === 'RICH_TEXT'
                "
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></MultiLineTextExecute>
              <SingleLineTextExecute
                :data="field"
                v-if="
                  field.input_type === 'SINGLE_LINE_TEXT' ||
                  field.input_type === 'EMAIL' ||
                  field.input_type === 'PASSWORD' ||
                  field.input_type === 'MASKED' ||
                  field.input_type === 'HYPER_LINK'
                "
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></SingleLineTextExecute>
              <MultiSelectExecute
                :data="field"
                v-if="field.input_type === 'MULTI_SELECT'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></MultiSelectExecute>
              <SelectExecute
                :data="field"
                v-if="field.input_type === 'SELECT'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></SelectExecute>
              <YesOrNoExecute
                :data="field"
                v-if="field.input_type === 'YES_OR_NO'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></YesOrNoExecute>
              <NumberExecute
                :data="field"
                v-if="
                  field.input_type === 'NUMBER' ||
                  field.input_type === 'ZIP_CODE'
                "
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></NumberExecute>
              <PhoneCountryCodeExecute
                :data="field"
                v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></PhoneCountryCodeExecute>
              <CurrencyExecute
                :data="field"
                v-if="field.input_type === 'CURRENCY'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></CurrencyExecute>
              <PaymentExecute
                :data="field"
                v-if="field.input_type === 'PAY_BUTTON'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></PaymentExecute>

              <HeadingExecute
                :data="field"
                v-if="field.input_type === 'HEADING'"
                :form="form"
                :is-view="!isExecute"
              ></HeadingExecute>
              <DIVExecute
                :data="field"
                v-if="field.input_type === 'DIVISION'"
                :form="form"
                :is-view="!isExecute"
                :colorFields="colorFields"
              ></DIVExecute>
              <CheckBoxGroupExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX_GROUP'"
                :form="form"
                :hasLabel="hasLabel"
                :is-view="!isExecute"
              ></CheckBoxGroupExecute>
              <CheckBoxExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="getFieldsData"
                :singleCompanyTemplateCheckBoxArray="
                  singleCompanyTemplateCheckBoxArray
                "
              ></CheckBoxExecute>
              <RadioExecute
                :data="field"
                v-if="field.input_type === 'RADIO'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="getFieldsData"
              ></RadioExecute>
              <ESignatureExecute
                :data="field"
                v-if="field.input_type === 'SIGNATURE'"
                :form="form"
              ></ESignatureExecute>
              <GlobalVariableExecute
                :data="field"
                v-if="field.input_type === 'GLOBAL_VARIABLE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
              ></GlobalVariableExecute>

              <EntityVariableExecute
                :data="field"
                v-if="field.input_type === 'ENTITY_VARIABLE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
              ></EntityVariableExecute>

              <EntityExecute
                :data="field"
                v-if="field.input_type === 'ENTITY'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
                :rules_hide="true"
              ></EntityExecute>

              <ParagraphExecute
                :data="field"
                v-if="field.input_type === 'PARAGRAPH'"
                :form="form"
                :is-view="!isExecute"
              ></ParagraphExecute>
              <SingleLineContentExecute
                :data="field"
                v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></SingleLineContentExecute>
              <AuthorizedSignatureExecute
                :data="field"
                v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                :form="form"
                :is-view="!isExecute"
              ></AuthorizedSignatureExecute>
              <!-- IMage html formula -->
              <FormulaExecute
                :data="field"
                v-if="field.input_type === 'FORMULA'"
                :form="form"
                :is-view="!isExecute"
                :workflowData="getUserWorkflowData"
                :hasLabel="hasLabel"
              ></FormulaExecute>
              <ActionButtonExecute
                :data="field"
                v-if="field.input_type === 'ACTION_BUTTON'"
                :form="form"
                :is-view="!isExecute"
                :workflowDataId="workflowDataId"
                :fieldsData="fieldsData"
              ></ActionButtonExecute>

              <ImageExecute
                :data="field"
                v-if="
                  field.input_type === 'IMAGE' || field.input_type === 'GIF'
                "
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></ImageExecute>
              <VideoExecute
                :data="field"
                v-if="field.input_type === 'VIDEO'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></VideoExecute>
              <HtmlExecute
                :data="field"
                v-if="field.input_type === 'HTML_CONTENT'"
                :form="form"
                :is-view="!isExecute"
              ></HtmlExecute>
              <ListExecute
                :data="field"
                v-if="field.input_type === 'LIST'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></ListExecute>
              <AggregateFunctionExecute
                :data="field"
                v-if="field.input_type === 'AGGREGATE_FUNCTION'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :workflowDataId="workflowDataId"
              ></AggregateFunctionExecute>
              <QuestionExecute
                :data="field"
                v-if="field.input_type === 'QUESTION'"
                :form="form"
                :is-view="!isExecute"
              ></QuestionExecute>
              <AutoIncrementExecute
                :data="field"
                v-if="field.input_type === 'AUTO_INCREMENT_NUMBER'"
                :form="form"
                :hasLabel="hasLabel"
                :checkIsDisabled="false"
                :fromEntityViews="fromEntityViews"
                :isView="fromEntityViews"
                :isIconRequired="templateStyles?.icons_required"
              ></AutoIncrementExecute>
              <WeekDaysExecute
                :data="field"
                v-if="field.input_type == 'WEEKDAYS'"
                :form="form"
                :hasLabel="hasLabel"
                :is-view="!isExecute"
              ></WeekDaysExecute>
              <RadioGroupExecute
                :data="field"
                v-if="field.input_type == 'RADIO_BUTTON_GROUP'"
                :form="form"
                :hasLabel="hasLabel"
                :is-view="!isExecute"
              ></RadioGroupExecute>

              <DataTableExecute
                :data="field"
                v-if="field.input_type === 'DATA_TABLE'"
                :form="form"
                :is-view="!isExecute"
                :fieldsData="fieldsData"
              ></DataTableExecute>
              <EntityTableExecute
                :data="field"
                :form="form"
                :is-view="!isExecute"
                :fieldsData="fieldsData"
                v-if="field.input_type === 'ENTITY_TABLE'"
              >
              </EntityTableExecute>
              <StarRatingExecute
                :data="field"
                v-if="field.input_type === 'STAR_RATING'"
                :form="form"
                :is-view="!isExecute"
                :fieldsData="fieldsData"
              ></StarRatingExecute>
              <ConcatenateExecute
                :data="field"
                v-if="field.input_type === 'CONCATENATE'"
                :form="form"
                :is-view="!isExecute"
                :fieldsData="fieldsData"
              ></ConcatenateExecute>
              <RandomTextExecute
                :data="field"
                v-if="field.input_type === 'RANDOM_TEXT'"
                :form="form"
                :is-view="!isExecute"
                :fieldsData="fieldsData"
              ></RandomTextExecute>
              <IntegrationExecute
                :data="field"
                v-if="field.input_type == 'INTEGRATION'"
                :form="form"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
                :is-view="!isExecute"
              >
              </IntegrationExecute>
              <IntegrationVariableExecute
                :data="field"
                v-if="field.input_type == 'INTEGRATION_VARIABLE'"
                :form="form"
                :hasLabel="hasLabel"
                :is-view="!isExecute"
                :fieldsData="fieldsData"
              >
              </IntegrationVariableExecute>
              <CurrencyTypesExecute
                :data="field"
                v-if="field.input_type === 'CURRENCY_TYPE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
              ></CurrencyTypesExecute>
              <CarouselExecute
                :data="field"
                v-if="field.input_type === 'CAROUSEL'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
              ></CarouselExecute>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </section>
</template>

<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";

export default {
  name: "templates-NewPreview-Template",
  components: {
    //RadioButtonGroupExecute
    RadioGroupExecute: () =>
      import("./formComponentsExecute/RadioButtonGroupExecute"),
    HorizontalLineExecute: () =>
      import("./formComponentsExecute/HorizontalLineExecute"),
    DateExecute: () => import("./formComponentsExecute/DateExecute"),
    DateTimeExecute: () => import("./formComponentsExecute/DateTimeExecute"),
    DateTimeRangeExecute: () =>
      import("./formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () => import("./formComponentsExecute/DateRangeExecute"),
    TimeExecute: () => import("./formComponentsExecute/TimeExecute"),
    TimeRangeExecute: () => import("./formComponentsExecute/TimeRangeExecute"),
    FileExecute: () => import("./formComponentsExecute/FileExecute"),
    CheckBoxGroupExecute: () =>
      import("./formComponentsExecute/CheckBoxGroupExecute"),
    MultiLineTextExecute: () =>
      import("./formComponentsExecute/MultiLineTextExecute"),
    SingleLineTextExecute: () =>
      import("./formComponentsExecute/SingleLineTextExecute"),
    MultiSelectExecute: () =>
      import("./formComponentsExecute/MultiSelectExecute"),
    NumberExecute: () => import("./formComponentsExecute/NumberExecute"),
    PhoneCountryCodeExecute: () =>
      import("./formComponentsExecute/PhoneCountryCodeExecute"),
    SelectExecute: () => import("./formComponentsExecute/SelectExecute"),
    YesOrNoExecute: () => import("./formComponentsExecute/YesOrNoExecute"),
    HeadingExecute: () => import("./formComponentsExecute/HeadingExecute"),
    DIVExecute: () => import("./formComponentsExecute/DIVExecute"),
    CheckBoxExecute: () => import("./formComponentsExecute/CheckBoxExecute"),
    RadioExecute: () => import("./formComponentsExecute/RadioExecute"),
    ESignatureExecute: () =>
      import("./formComponentsExecute/ESignatureExecute"),
    ParagraphExecute: () => import("./formComponentsExecute/ParagraphExecute"),
    GlobalVariableExecute: () =>
      import("./formComponentsExecute/GlobalVariableExecute"),
    EntityVariableExecute: () =>
      import("./formComponentsExecute/EntityVariableExecute"),
    EntityExecute: () => import("./formComponentsExecute/EntityExecute"),
    SingleLineContentExecute: () =>
      import("./formComponentsExecute/SingleLineContentExecute"),
    AuthorizedSignatureExecute: () =>
      import("./formComponentsExecute/AuthorizedSignatureExecute"),
    ImageExecute: () => import("./formComponentsExecute/ImageExecute"),
    VideoExecute: () => import("./formComponentsExecute/VideoExecute"),
    FormulaExecute: () => import("./formComponentsExecute/FormulaExecute"),
    ActionButtonExecute: () =>
      import("./formComponentsExecute/ActionButtonExecute"),
    HtmlExecute: () => import("./formComponentsExecute/HtmlExecute"),

    ListExecute: () => import("./formComponentsExecute/ListExecute"),
    AggregateFunctionExecute: () =>
      import("./formComponentsExecute/AggregateFunctionExecute"),
    CurrencyExecute: () =>
      import("./formComponentsExecute/CurrencyExecute.vue"),
    QuestionExecute: () => import("./formComponentsExecute/QuestionExecute"),
    AutoIncrementExecute: () =>
      import("./formComponentsExecute/AutoIncrementExecute.vue"),
    WeekDaysExecute: () =>
      import("./formComponentsExecute/WeekDaysExecute.vue"),
    PaymentExecute: () => import("./formComponentsExecute/PaymentExecute"),
    PreviewInteractiveTemplateForm: () =>
      import("@/components/templates/PreviewInteractiveTemplateForm"),
    StarRatingExecute: () =>
      import("./formComponentsExecute/StarRatingExecute.vue"),
    DataTableExecute: () => import("./formComponentsExecute/DataTableExecute"),
    ConcatenateExecute: () =>
      import("./formComponentsExecute/ConcatenateExecute"),
    RandomTextExecute: () =>
      import("./formComponentsExecute/RandomTextExecute"),
    EntityTableExecute: () =>
      import("./formComponentsExecute/EntityTableExecute"),
    IntegrationExecute: () =>
      import("./formComponentsExecute/IntegrationExecute.vue"),
    IntegrationVariableExecute: () =>
      import("./formComponentsExecute/IntegrationVariableExecute.vue"),
    CurrencyTypesExecute: () =>
      import("./formComponentsExecute/CurrencyTypesExecute.vue"),
    CarouselExecute: () =>
      import("./formComponentsExecute/CarouselExecute.vue"),
  },

  props: [
    "workflowDataId",
    "templateData",
    "hasNext",
    "templateDataId",
    "workflowDetailsId",
    "isExecute",
    "workflowStepId",
    "templateDataIds",
    "fieldsData",
    "templateInteractiveQuestions",
    "templateInteractiveQuestionsType",
    "isHideFinishButton",
  ],
  data() {
    return {
      colorFields: {},
      form: {},
      repeatableTemplateFields: [],
      currentFormBuilderWidth: null,
      name: "",
      description: "",
      type: "",
      slug: "",
      storedFormBuilderWidth: null,
      isFlow: false,
      componentName: "",
      formHeight: 600,
      loading: false,
      templateFormData: {},
      templateCode: null,
      showForm: true,
      existedTemplateDataId: "",
      existedTemplateDataValues: {},
      preparingFormDataLoading: false,
      loadingText: "Loading Template..",

      fieldActions: [],
      templateStyles: {},
      hasRepeatableTemplate: false,
      refreshTemplate: false,
      tableStartingPoint: 0,
      loadGV: false,
      requiredFields: [],
      pos: {
        x: 0,
        y: 0,
        height: 0,
      },
      hasLabel: true,
    };
  },

  computed: {
    ...mapGetters("companyTemplates", [
      "getTemplateAddStatus",
      "getTemplateUpdateStatus",
      "getSingleCompanyTemplate",
      "getSingleDefaultTemplate",
      "getTemplateErrors",
    ]),

    ...mapGetters("workflowData", [
      "getNewWorkflowData",
      "getWorkflowDataCreateByTemplateStatus",
      "getWorkflowTemplateDataAddStatus",
      "getNewWorkflowUser",
      "getUserWorkflowData",
    ]),

    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getTemplatesDataUpdateStatus",
      "getFileUploadURL",
      "getFileUploadRefId",
      "getDocumentUploadStatus",
      "getDocumentUploadStatusUpdated",
      "getUploadedFileData",
    ]),

    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage",
    ]),

    textFieldPreviewClass() {
      return (inputType) => {
        const styles = ["common-field-preview"];
        if (
          inputType === "SINGLE_LINE_TEXT" ||
          inputType === "PASSWORD" ||
          inputType === "EMAIL" ||
          inputType === "MASKED"
        ) {
          styles.push("single-line-text-field-preview");
        } else if (inputType === "MULTI_LINE_TEXT") {
          styles.push("multi-line-text-field-preview");
        } else if (
          inputType === "NUMBER" ||
          inputType == "PHONE" ||
          inputType == "FAX" ||
          inputType == "ZIP_CODE" ||
          inputType == "CURRENCY"
        ) {
          styles.push("number-field-preview");
        } else if (inputType === "DATE") {
          styles.push("date-field-preview");
        }

        return styles;
      };
    },

    getTemplateSlug() {
      return this.name.toLowerCase().split(" ").join("-");
    },

    getStylediv() {
      return `height:${this.formHeight + 200}px;` + this.getElementStyle;
    },
    getFieldsData() {
      this.fieldsData.forEach((f) => {
        if (
          f.input_type == "ACTION_BUTTON" &&
          f.action_button_type === "TEMPLATE"
        ) {
          this.pos.x = f.x;
          this.pos.y = f.y;
          this.pos.height = f.height;
        }
      });
      this.fieldsData.forEach((f) => {
        if (
          f.input_type != "ACTION_BUTTON" &&
          (this.pos.x > 0 || this.pos.y > 0 || this.pos.height > 0)
        ) {
          if (this.pos.y < f.y) {
            if (this.pos.y + this.pos.height + 150 < f.y) {
              return;
            } else {
              f.y = this.pos.y + this.pos.height + 200;
            }
          }
        }
      });
      return this.fieldsData;
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.getFieldsData) {
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? `background: ${this.templateStyles.background}`
          : "";
      }
      return borderStyle;
    },
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });

      return obj;
    },

    getStyle() {
      return (item) => {
        let itemWidth =
          item.styles && item.styles.labelStyle == "right"
            ? item.width + 100
            : item.width;
        let style = `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${itemWidth}px;`;
        return style;
      };
    },
  },

  async mounted() {
    await this.getAllFieldList();
    this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    await this.setTemplateInfo();
    this.$store.commit(
      "companyTemplates/setTemplateErrors",
      {},
      {
        root: true,
      }
    );

    this.$store.dispatch("repeatableTemplates/resetParentEntityData");

    bus.$on("repeatable-template-added", () => {
      this.refreshTemplate = true;
      setTimeout(() => {
        this.refreshTemplate = false;
      }, 1000);
    });
  },

  methods: {
    getDefaultSizeRangesByFieldType(inputType) {
      if (
        inputType === "SINGLE_LINE_TEXT" ||
        inputType === "PASSWORD" ||
        inputType === "EMAIL" ||
        inputType === "MASKED"
      ) {
        inputType = "SINGLE_LINE_TEXT";
      } else if (inputType === "MULTI_LINE_TEXT" || inputType === "RICH_TEXT") {
        inputType = "SINGLE_LINE_TEXT";
      } else if (
        inputType === "NUMBER" ||
        inputType === "PHONE" ||
        inputType === "FAX" ||
        inputType === "ZIP_CODE" ||
        inputType === "CURRENCY"
      ) {
        inputType = "NUMBER";
      } else if (
        inputType === "DATE" ||
        inputType === "TIME" ||
        inputType === "DATE_TIME"
      ) {
        inputType = "DATE";
      }
      let data = this.fieldActionsObject[inputType];
      return {
        min_height: data.min_height,
        min_width: data.min_width,
        max_height: data.max_height,
        max_width: data.max_width,
      };
    },

    updateHeight({ start, end }) {
      this.fieldsData.forEach((f) => {
        if (f.y > start && f.y <= end) {
          f.y = end + (f.y - start);
        }
      });
    },

    getStyleForTable() {
      return (
        "position:absolute;top:" +
        this.tableStartingPoint +
        "px;left:20px;width:97%;"
      );
      // return "top:" + 1000 + "px;left:40px;";
    },

    saveAndnext() {
      if (!this.isExecute) {
        bus.$emit("next-step", this.form);
        return;
      }

      if (this.workflowDataId) {
        if (this.checkRequiredFields()) {
          this.$notify.error({
            title: "Error",
            message: this.requiredFields.join(",") + " are required",
          });
        } else {
          if (this.templateDataId) {
            this.updateTemplateData();
          } else {
            this.addTemplateDateToWorkflow();
          }
        }
      } else {
        this.addNewWorkflowData();
      }
    },
    next() {
      if (!this.isExecute) {
        bus.$emit("next-step", this.form);
        return;
      }
    },

    goToEditWorkflow() {
      let urlData = this.$router.resolve({
        path: `/wf/${this.$route.params.workflow_code}/${this.$route.params.template_code}/${this.workflowDataId}`,
      });
      window.location.href = urlData.href;
    },
    checkRequiredFields() {
      let showError = false;
      this.requiredFields = [];
      this.fieldsData.forEach((field) => {
        if (field.validations && field.validations.required) {
          if (
            field.validations.type &&
            field.validations.type == "ARRAY" &&
            field.key &&
            this.form[field.key] &&
            !this.form[field.key].length
          ) {
            showError = true;
            this.requiredFields.push(field.label);
          } else if (field.key && !this.form[field.key]) {
            showError = true;
            this.requiredFields.push(field.label);
          }
        }
      });
      return showError;
    },
    async updateTemplateData() {
      try {
        let params = {
          template_data: await this.prepareFormData(),
          template_data_id: this.templateDataId,
          workflow_step_id: this.workflowStepId,
        };
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );

        if (this.getTemplatesDataUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Data updated successfully",
          });
          this.updateTempleteCompletionStatus();
          bus.$emit("next-step", {});
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error at updating data",
          });
        }
      } catch (err) {
        console.log("updateTemplateData", err);

        this.$notify.error({
          title: "Error",
          message: "Error at updating data",
        });
      }
    },

    async updateTempleteCompletionStatus() {
      let params = {
        workflow_data_id: this.workflowDataId,
        step_type: "TEMPLATE",
        template_data_id: this.templateDataId,
        workflow_step_id: this.workflowStepId,
        completion_status:
          this.getRquiredFieldsCount() === this.getTotalFieldsCount(),
      };
      await this.$store.dispatch(
        "workflowData/updateStepCompletionStatus",
        params
      );
    },

    async addTemplateDateToWorkflow() {
      try {
        let data = {
          workflow_data_id: this.workflowDataId,
          template_id: this.templateData._id,
          template_data: await this.prepareFormData(),
          workflow_step_id: this.workflowStepId,

          template_completion_status:
            this.getRquiredFieldsCount() === this.getTotalFieldsCount(),
        };

        if (this.existedTemplateDataId) {
          data.template_data_id = this.existedTemplateDataId;
        }
        await this.$store.dispatch(
          "workflowData/addTemplateDataToWorkFlowData",
          data
        );
        if (this.getWorkflowTemplateDataAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Data saved successfully",
          });

          bus.$emit("next-step", {
            message: "Template saved",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error at saving data",
          });
        }
      } catch (err) {
        console.log("addTemplateDateToWorkflow", err);
      }
    },
    async addNewWorkflowData() {
      try {
        let data = {
          workflow_details_id: this.workflowDetailsId,
          template_id: this.templateData._id,
          template_data: this.form,
          user_id: this.getNewWorkflowUser,
          workflow_step_id: this.workflowStepId,
          template_completion_status:
            this.getRquiredFieldsCount() === this.getTotalFieldsCount(),
        };
        if (this.existedTemplateDataId) {
          data.template_data_id = this.existedTemplateDataId;
        }
        await this.$store.dispatch(
          "workflowData/createWorkFlowDataByTemplateData",
          data
        );
        if (this.getWorkflowDataCreateByTemplateStatus) {
          this.$store.commit("workflowData/setNewWorkflowUser", null, {
            root: true,
          });
          if (this.getNewWorkflowData) {
            bus.$emit("next-step", {
              newWorkflowData: this.getNewWorkflowData,
            });
          }
        }
      } catch (err) {
        console.log("addNewWorkflowData", err);
      }
    },

    getRquiredFieldsCount() {
      return this.fieldsData.filter(
        (e) => e.validations && e.validations.required && this.form[e.key]
      ).length;
    },

    getTotalFieldsCount() {
      return this.fieldsData.filter(
        (e) => e.validations && e.validations.required && !e.value
      ).length;
    },

    async setTemplateInfo() {
      this.loading = true;

      if (this.templateDataId && this.templateDataId.length) {
        await this.fetchUserTemplateData();
      } else if (
        !this.templateDataId &&
        (this.getNewWorkflowUser || this.workflowStepId)
      ) {
        await this.fetchUserExistedTemplateData();
      }
      // await this.setTemplateElements(this.templateData);
      this.loading = false;
    },

    calculateRelativeLeft(x) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
        );
      }
      return x;
    },

    calculateRelativeTop(y) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
        );
      }
      return y;
    },
    getHandleByType(type) {
      let defaultHandles = ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"];
      switch (type) {
        case "SELECT":
          return ["mr", "ml"];
        case "date":
          return ["mr", "ml"];
        case "CHECKBOX":
          return defaultHandles;
        case "RADIO":
          return defaultHandles;
        case "MULTI_LINE_TEXT":
          return ["mr", "ml"];
        default:
          return defaultHandles;
      }
    },
    calculateRelativeWidth(width) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
        );
      }
      return width;
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          ...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        if (width > field.max_width) {
          field.width = field.max_width;
        } else if (width < field.min_width) {
          field.width = field.min_width;
        } else {
          field.width = width;
        }
        if (height > field.max_height) {
          field.height = field.max_height;
        } else if (height < field.min_height) {
          field.height = field.min_height;
        } else {
          field.height = height;
        }
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        return field;
      });
    },
    async setTemplateElements(templateData) {
      this.name = templateData.name;
      this.description = templateData.description;
      this.type = templateData.type;
      this.slug = templateData.slug;
      this.templateStyles = templateData.styles;

      this.storedFormBuilderWidth = templateData.page_base_width;
      if (this.type == "STANDARD") {
        this.showFields = false;
      } else {
        let defaultSection = templateData.sections.find(
          (x) => x.type == "DEFAULT"
        ); // Todo need to change for multiple sections
        // this.fieldsData = defaultSection.fields;

        // we are sorting all fields depends on x,y cordinates to flow in the form
        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          if (a.y - b.y < 10) {
            return a.x - b.x;
          }
          return a.y - b.y;
        });

        this.fieldsData = this.setSizeAndPositionForElements(
          defaultSection.fields
        );

        this.repeatableTemplateFields = this.fieldsData.filter(
          (e) =>
            e.input_type == "ACTION_BUTTON" &&
            e.action_button_repeating_template
        );
        if (this.templateDataId && this.getTemplatesData) {
          this.showForm = false;
          this.setTemplateDataToFormFields(
            this.fieldsData,
            this.getTemplatesData.template_data
          );
        } else if (
          this.existedTemplateDataId &&
          this.existedTemplateDataValues
        ) {
          this.setTemplateDataToFormFields(
            this.fieldsData,
            this.existedTemplateDataValues
          );
        }

        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          return a.y - b.y;
        });

        let lastElement = defaultSection.fields[this.fieldsData.length - 1];

        this.tableStartingPoint = lastElement.y + lastElement.height + 30;

        this.formHeight = lastElement.y + lastElement.height + 200;
      }
    },

    setTemplateDataToFormFields(fieldsData, data) {
      return fieldsData.forEach((field) => {
        if (data && data[field.key]) {
          this.$set(this.form, field.key, data[field.key]);
        }
      });
    },

    async saveForm() {
      bus.$emit("finish", this.form);
    },
    async fetchUserTemplateData() {
      await this.$store.dispatch("templatesData/fetchUserTemplateData", {
        template_data_id: this.templateDataId,
      });
    },
    async fetchUserExistedTemplateData() {
      if (this.getNewWorkflowUser || this.getUserWorkflowData.user_id) {
        await this.$store.dispatch("templatesData/fetchUserTemplateData", {
          user_id: this.getNewWorkflowUser || this.getUserWorkflowData.user_id,
          template_id: this.templateData._id,
        });
        if (this.getTemplatesData && this.getTemplatesData.length) {
          this.existedTemplateDataId = this.getTemplatesData[0]._id;
          this.existedTemplateDataValues =
            this.getTemplatesData[0].template_data;
        }
      } else {
        this.existedTemplateDataId = null;
        this.existedTemplateDataValues = {};
      }
    },

    async prepareFormData() {
      this.preparingFormDataLoading = true;
      this.loading = true;
      this.loading = "Preparing template data...";
      for (let [key, value] of Object.entries(this.form)) {
        if (
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.form[key].path = path;
        }
      }
      this.loading = false;

      this.preparingFormDataLoading = false;
      return this.form;
    },

    // methods to file upload

    async uploadFile(file) {
      file.forEach(async (el) => {
        await this.getFileUploadUrl(el);
        await this.uploadSelectedDocument(el.raw);
        if (this.getUploadedFileData) {
          return (
            this.getUploadedFileData.slug + "/" + this.getUploadedFileData.key
          );
        } else {
          return "";
        }
      });
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );

      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          console.log("fileuploaded");
        } else {
          this.$message("Sorry ! Error while file uploading");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    async getAllFieldList() {
      try {
        let params = "form=true&content=true";
        await this.$store.dispatch("standardFields/getFieldLists", params);
        if (this.getFList) {
          let dFieldList = this.getFList;
          this.fieldActions = [
            ...dFieldList.allFormFields,
            ...dFieldList.allContentFields,
            ...dFieldList.allAdvancedFields,
          ];
        }
      } catch (error) {
        console.log("getAllFieldList", error);
      }
    },
  },
  watch: {
    "$route.params.slug"(slug) {
      this.currentSlug = slug;
    },
    "$route.params.workflow_data_id"(id) {
      this.workflowDataId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-btn {
  border-radius: 2px;
  padding: 0.45em 0.65em !important;
  border: 1px solid #285ed3;
  background: transparent;
  color: #285ed3 !important;
  &:hover {
    color: white !important;
    background: #285ed3;
    border: 1px solid #285ed3 !important;
  }
}
.edit-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  border: 1px solid #f68c8c;
  background: transparent;
  color: #f68c8c !important;
  &:hover {
    color: white !important;
    background: #f68c8c !important;
    border: 1px solid #f68c8c !important;
  }
}
.preview-template-view {
  margin: 1em 0em;
  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .preview-form-header {
    margin-top: 1em;
    z-index: 100;
    h1 {
      font-size: 2.1em;
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }
    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }
  .header-action-block {
    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin: 0.5em 0 0 auto;
    }
  }
  .footer-action-block {
    position: relative;
    margin-bottom: 2em;
    .el-button {
      margin: 2em auto 0;
      display: block;
      color: #ffffff;
      padding: 0.5em 1em;
      min-width: 150px;
      font-size: 1em;
      border-radius: 30px !important;
    }
    .required-fields-info {
      top: 10px;
      font-size: 14px;
      & > div {
        margin-right: 1.5em;
        border: 1px solid #eeeeee;
        padding: 0.65em 1em;
        border-radius: 4px;
        font-size: 1em;
        letter-spacing: 0.5px;
      }
    }
  }
  .form-builder {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 210px) !important;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.05);
    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .each-element img {
        max-width: 100%;
      }
    }
  }
  .preview-template-view {
    .form-builder {
      .form-fields-holder {
        @media (max-width: 991.98px) {
          display: flex;
          flex-direction: column;
          & > div {
            position: relative !important;
            left: 20px !important;
            top: 0px !important;
            // margin-top: 30px;
            margin-bottom: 10px;
            min-height: 50px;
            width: calc(100% - 40px) !important;
            .esignature-execute {
              left: 0px !important;
            }
          }
        }
        & > div {
          margin-top: 20px;
          & > .form-group {
            height: 100%;
            & > div {
              &:first-child {
                height: 21px;
              }
              &:nth-child(2) {
                height: 100%;
                .el-input {
                  // height: 100% !important;
                  input {
                    // height: calc(100% - 21px);
                    // height: calc(100% - 0px);
                    // min-height: 30px;
                    height: 100%;
                  }
                }
              }
            }

            & > .is-align-middle {
              & > div {
                height: 100%;
                .el-textarea {
                  height: 100% !important;
                  textarea {
                    height: calc(100% + 21px);
                    min-height: 0px !important;
                  }
                }
                .el-select {
                  // height: 100% !important;
                  .el-input {
                    height: 100%;
                    input {
                      height: 100%;
                      // min-height: 0px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
